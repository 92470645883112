import { Dimensions, StyleSheet, View, Text } from "react-native";
import Search from "./application/views/SearchView";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Home from "./application/views/Home";
import { Accessibility } from "./application/types/Accessibility.type";
import i18next from "i18next";
import { en, he } from "./i18";
import { registerRootComponent } from "expo";
import { getLanguage } from "./application/services/LocalStorage.service";
import Icon from "react-native-vector-icons/FontAwesome";
import { Copyright } from "./application/components/Copyright";

const Stack = createNativeStackNavigator();

// if (!getLanguage()) setLanguage("he");

export let language: string = getLanguage();

export const direction = language === "en" ? "ltr" : "rtl";

i18next.init({
  compatibilityJSON: "v3",
  lng: language,
  debug: true,
  resources: {
    en: {
      translation: en,
    },
    he: {
      translation: he,
    },
  },
});

export type RootStackParamList = {
  Home: undefined;
  Search: { carNumber: number; carDetails: Accessibility };
};

export default function App() {
  return (
    <>
      <NavigationContainer>
        <Stack.Navigator>
          <Stack.Screen
            name="Home"
            component={Home}
            options={navigatorSettings}
          />
          <Stack.Screen
            name="Search"
            component={Search}
            options={navigatorSettingsWithReturn("")}
          />
        </Stack.Navigator>
      </NavigationContainer>
      <Copyright />
    </>
  );
}

const navigatorSettings = {
  headerShown: false,
  headerTransparent: true,
  headerShadowVisible: false,
};

const navigatorSettingsWithReturn = (title: string) => {
  return {
    title,
    headerShown: true,
    headerTransparent: true,
    headerShadowVisible: false,
  };
};

export const mainAppStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  font: {
    padding: 10,
  },
  textInput: {
    height: 40,
    borderWidth: 1,
    margin: 10,
    textAlign: "center",
  },
  copyright: {
    width: "100%",
    height: 30,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
});

registerRootComponent(App);
