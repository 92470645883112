import i18next from "i18next";
import { StyleSheet, Text, View } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import Flags from "country-flag-icons/react/3x2";
import { direction } from "../../App";
import { setLanguage } from "../services/LocalStorage.service";
import { Colors } from "../static";

export default function HomePageHeader() {
  return (
    <>
      <View style={styles.header}>
        <Text>
          <Flags.US
            title="United States"
            onClick={() => {
              setLanguage("en");
            }}
            style={{ height: 25, margin: 5, cursor: "pointer" }}
          />
          <Flags.IL
            title="Israel"
            onClick={() => {
              setLanguage("he");
            }}
            style={{ height: 25, margin: 5, cursor: "pointer" }}
          />
        </Text>
      </View>
      <View style={styles.header}>
        <Text
          style={{ fontSize: 40, fontWeight: "bold", color: Colors.LightBlue }}
        >
          {i18next.t("homeSearchTitle")}
        </Text>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    marginTop: 30,
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    direction: direction,
  },
  flag: { height: 25, margin: 5, cursor: "pointer" },
});
