import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useEffect, useState } from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import { RootStackParamList } from "../../App";
import CarTavStatus from "../components/CarTavStatus";
import { Tile } from "../components/Tile";
import { Accessibility } from "../types/Accessibility.type";
import { Details } from "../types/Details.type";
import {
  getTavStatus,
  setCarTavDetailsToList,
} from "../utils/accessibilityUtil";
import { setCarDetailsToList } from "../utils/carDetailsUtil";

type Props = NativeStackScreenProps<RootStackParamList, "Search">;
type newProps = {
  carTavDetailsParam: Accessibility | undefined;
  carDetailsParam: Details | undefined;
};

export default function Search({
  carDetailsParam,
  carTavDetailsParam,
}: newProps) {
  const [foundResults, setFoundResults] = useState(false);
  const [findingError, setFindingError] = useState(false);
  const [flatListItems, setFlatListItems] = useState<{ [key: string]: any }[]>(
    [],
  );

  useEffect(() => {
    if (!carTavDetailsParam) {
      setFindingError(true);
      return;
    }
    const tempList: any[] = [];
    setCarDetailsToList(carDetailsParam, tempList);
    setCarTavDetailsToList(carTavDetailsParam, tempList);
    if (tempList.length) {
      setFlatListItems([...tempList]);
      setFoundResults(true);
    } else {
      setFoundResults(false);
    }
  }, [carTavDetailsParam, carDetailsParam]);

  if (findingError)
    return (
      <View>
        <Text>Search Error !</Text>
      </View>
    );
  return (
    <View style={styles.container}>
      <CarTavStatus tavStatus={getTavStatus(carTavDetailsParam)} />
      <FlatList
        columnWrapperStyle={styles.view}
        showsVerticalScrollIndicator={false}
        numColumns={2}
        data={flatListItems}
        renderItem={(item) => (
          <Tile title={item.item.title} value={item.item.value} />
        )}
      ></FlatList>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 30,
  },
  view: { alignItems: "center", justifyContent: "center" },
});
