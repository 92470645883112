import i18next from "i18next";
import {
  StyleSheet,
  View,
  Alert,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import { direction } from "../../App";
import Icon from "react-native-vector-icons/FontAwesome";
import { Colors } from "../static";

type SearchBarType = {
  carNumber: number | undefined;
  setCarNumber: React.Dispatch<React.SetStateAction<number | undefined>>;
  sendCarNumber: () => Promise<void>;
};

export default function SearchBar({
  carNumber,
  setCarNumber,
  sendCarNumber,
}: SearchBarType) {
  const handleKeyPress = (keyValue: string) => {
    if (keyValue === "Enter") {
      sendCarNumber();
      Keyboard.dismiss();
    }
  };

  return (
    <View
      style={{
        marginTop: 30,
        flexDirection: "row",
        direction: direction,
      }}
    >
      <View style={searchBarStyle.searchContainer}>
        <Icon
          onPress={sendCarNumber}
          name="search"
          size={25}
          style={searchBarStyle.searchIcon}
        />
        <TouchableWithoutFeedback accessible={false}>
          <TextInput
            style={searchBarStyle.input}
            onChangeText={(text) => setCarNumber(Number(text))}
            keyboardType="number-pad"
            maxLength={10}
            placeholder={i18next.t("homeSearchTitle")}
            value={carNumber ? String(carNumber) : ""}
            onKeyPress={(e) => handleKeyPress(e.nativeEvent.key)}
            multiline={true}
            onSubmitEditing={() => sendCarNumber()}
          />
        </TouchableWithoutFeedback>
        <Icon
          onPress={() => {
            setCarNumber(undefined);
            Keyboard.dismiss();
          }}
          name="close"
          size={25}
          style={searchBarStyle.closeIcon}
        />
      </View>
    </View>
  );
}

export const searchBarStyle = StyleSheet.create({
  searchIcon: {
    marginHorizontal: 20,
  },
  closeIcon: {
    marginHorizontal: 20,
  },
  searchContainer: {
    height: 50,
    backgroundColor: Colors.LightGrey,
    borderRadius: 10,
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    direction: direction,
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.TypeBlack,
    flex: 1,
    padding: 5,
    textAlign: "center",
    width: "50%",
  },
});
