import { Dimensions, Text, View, StyleSheet } from "react-native";
import { Colors } from "../static";

const screenWidth = Dimensions.get("screen").width;
const screenHeight = Dimensions.get("screen").height;
const tileWidth = screenWidth / 2 - 30;
const tileHeight = screenHeight / 4 - 30;

export const Tile = ({ title, value }: { title: string; value: string }) => {
  if (title === "" || value === "") return <></>;
  return (
    <View style={styles.main}>
      <View style={styles.textArea}>
        <View>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View>
          <Text style={styles.subTitle}>{value}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    backgroundColor: Colors.LightBlue,
    width: tileWidth,
    height: tileHeight,
    maxHeight: 175,
    maxWidth: 175,
    borderRadius: 10,
    marginHorizontal: 5,
    marginBottom: 15,
    padding: 10,
    justifyContent: "center",
  },
  textArea: { alignItems: "center" },
  title: { fontSize: 16, fontWeight: "bold" },
  subTitle: { fontSize: 14 },
});
