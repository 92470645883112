import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { RootStackParamList } from "../../App";
import { findCar as accessibilityFindCar } from "../utils/accessibilityUtil";
import Search from "./SearchView";
import { Accessibility } from "../types/Accessibility.type";
import SearchBar from "../components/SearchBar";
import HomePageHeader from "../components/HomePageHeader";
import { Loading } from "../components/Loading";
import { findCar as carDetailsFind } from "../utils/carDetailsUtil";
import { Details } from "../types/Details.type";
import { Keyboard } from "react-native";

type Props = NativeStackScreenProps<RootStackParamList>;

export default function Home({ navigation, route }: Props) {
  const [carNumber, setCarNumber] = useState<number>();
  const [loadSearch, setLoadSearch] = useState(false);
  const [carTavDetails, setCarTavDetails] = useState<
    Accessibility | undefined
  >();
  const [carDetails, setCarDetails] = useState<Details | undefined>();
  const [loading, setLoading] = useState(false);

  const sendCarNumber = async () => {
    Keyboard.dismiss();
    if (carNumber) {
      setLoading(true);
      setLoadSearch(false);
      const [carTavDetails, tavStatus] = await accessibilityFindCar(
        String(carNumber),
      );
      const [carDetails, detailsStatus] = await carDetailsFind(
        String(carNumber),
      );
      setCarTavDetails(carTavDetails);
      setCarDetails(carDetails);
      setLoadSearch(true);
      setLoading(false);
    } else {
      setLoadSearch(false);
    }
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingHorizontal: 20,
      }}
    >
      <HomePageHeader />
      <SearchBar
        setCarNumber={setCarNumber}
        sendCarNumber={sendCarNumber}
        carNumber={carNumber}
      />

      {loading && <Loading />}
      {loadSearch && (
        <Search
          carDetailsParam={carDetails}
          carTavDetailsParam={carTavDetails}
        />
      )}
    </SafeAreaView>
  );
}
