const localStorageLanguageKey = "language";

export const getLanguage = (): string => {
  let lang = localStorage.getItem(localStorageLanguageKey);
  if (lang) return lang;
  setLanguage("he");
  return "he";
};

export const setLanguage = (lang: string): void => {
  localStorage.setItem(localStorageLanguageKey, lang);
  window.location.reload();
};
