import i18next from "i18next";
import moment from "moment";
import { getItemToFlatList } from ".";
import { Config } from "../config/config";
import { accessibilityAxios } from "../services/Api.service";
import { Accessibility } from "../types/Accessibility.type";

export const findCar = async (
  carNumber: string,
): Promise<[Accessibility, number]> => {
  const res = await accessibilityAxios.post(
    Config.ACCESSIBILITY_URL,
    {
      resource_id: "c8b9f9c8-4612-4068-934f-d4acd2e3c06e",
      limit: 1,
      q: carNumber,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    },
  );

  return [res.data as Accessibility, res.status];
};

export const setDateToRegularDate = (tavDate: string | number): string => {
  return moment(tavDate, "YYYYMMDD").format("DD/MM/YYYY");
};

export const setCarTavDetailsToList = (
  carTavDetailsParam: Accessibility | undefined,
  temporaryList: any[],
) => {
  if (carTavDetailsParam && carTavDetailsParam.result.records.length) {
    const tavDetails = carTavDetailsParam.result.records[0];
    temporaryList.push(
      getItemToFlatList(
        i18next.t("searchTavDate"),
        setDateToRegularDate(tavDetails["TAARICH HAFAKAT TAG"]),
      ),
    );
    temporaryList.push(
      getItemToFlatList(i18next.t("searchTavType"), tavDetails["SUG TAV"]),
    );
    temporaryList.push(
      getItemToFlatList(i18next.t("searchRank"), tavDetails.rank),
    );
  }
};

export const getTavStatus = (
  carTavDetailsParam: Accessibility | undefined,
): boolean => {
  return carTavDetailsParam && carTavDetailsParam.result.records.length
    ? true
    : false;
};
