import { NativeStackScreenProps } from "@react-navigation/native-stack";
import i18next, { t } from "i18next";
import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { RootStackParamList } from "../../App";

type Props = NativeStackScreenProps<RootStackParamList, "Search">;
type newProps = {
  tavStatus: boolean;
};

export default function CarTavStatus({ tavStatus }: newProps) {
  useEffect(() => {}, [tavStatus]);

  if (tavStatus)
    return (
      <View style={styles({ tavStatus }).main}>
        <Icon style={styles({ tavStatus }).icon} name="check" size={25} />
        {i18next.t("tavFound")}
      </View>
    );

  return (
    <View style={styles({ tavStatus }).main}>
      <Icon style={styles({ tavStatus }).icon} name="remove" size={25} />
      {i18next.t("noTavFound")}
    </View>
  );
}

const styles = (props: any) =>
  StyleSheet.create({
    main: {
      width: "100%",
      height: 50,
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      marginBottom: 20,
    },
    icon: {
      color: props.tavStatus === true ? "green" : "red",
    },
  });
