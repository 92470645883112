import { View, Text } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { mainAppStyles } from "../../App";

const iconSize = 20;

export const Copyright = () => {
  return (
    <View style={mainAppStyles.copyright}>
      <Text>
        Aviv Maman &copy; {new Date().getFullYear()}{" "}
        <Icon
          name="github"
          onPress={() =>
            (window.location.href = "https://github.com/avivmaman")
          }
          size={iconSize}
        />{" "}
        <Icon
          name="linkedin-square"
          onPress={() =>
            (window.location.href =
              "https://linkedin.com/in/aviv-maman-63aa6a105")
          }
          size={iconSize}
        />{" "}
        <Icon
          name="envelope-o"
          onPress={() =>
            (window.location.href = "mailto:phalanx-tweet0q@icloud.com")
          }
          size={iconSize}
        />
      </Text>
    </View>
  );
};
