import i18next from "i18next";
import moment from "moment";
import { getItemToFlatList } from ".";
import { Config } from "../config/config";
import { carDetailsAxios } from "../services/Api.service";
import { Details } from "../types/Details.type";

export const findCar = async (
  carNumber: string,
): Promise<[Details, number]> => {
  const res = await carDetailsAxios.post(
    Config.ACCESSIBILITY_URL,
    {
      resource_id: "053cea08-09bc-40ec-8f7a-156f0677aff3",
      q: carNumber,
      filters: {},
      include_total: true,
      send_analytics_event: true,
      limit: 100,
      offset: 0,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    },
  );

  return [res.data as Details, res.status];
};

export const setCarDetailsToList = (
  carDetailsParam: Details | undefined,
  temporaryList: any[],
) => {
  if (carDetailsParam && carDetailsParam.result.records.length) {
    const carDetails = carDetailsParam.result.records[0];
    temporaryList.push(
      getItemToFlatList(
        i18next.t("searchCarManufacture"),
        carDetails.tozeret_nm,
      ),
    );
    temporaryList.push(
      getItemToFlatList(i18next.t("searchCarColor"), carDetails.tzeva_rechev),
    );
    temporaryList.push(
      getItemToFlatList(i18next.t("searchCarNumber"), carDetails.mispar_rechev),
    );
  }
};
