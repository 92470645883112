export const en = {
  homeSearch: "Search",
  homeSearchTitle: "Search car number",
  searchCarNumber: "Car number",
  searchSinceDate: "Since",
  searchIsThisCarHaveTav: "This car have tav ?",
  searchTavDate: "Issue date",
  searchTavType: "Type",
  searchRank: "Rank",
  searchCarColor: "Car color",
  searchCarManufacture: "Car manufacture",
  tavFound: "Car found on Tav database",
  noTavFound: "Car not found on Tav database",
};

export const he = {
  homeSearch: "חיפוש",
  homeSearchTitle: "חפש מספר רכב",
  searchCarNumber: "מספר רכב",
  searchSinceDate: "תו תקין מתאריך",
  searchIsThisCarHaveTav: "האם יש לרכב תו נכה?",
  searchTavDate: "תאריך הנפקת תו",
  searchTavType: "סוג תו",
  searchRank: "דירוג תו",
  searchCarColor: "צבע רכב",
  searchCarManufacture: "תוצרת הרכב",
  tavFound: "רכב זה נמצא במאגר תו נכה",
  noTavFound: "רכב זה אינו נמצא במאגר תו נכה",
};
