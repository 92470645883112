import Axios, { AxiosRequestConfig } from "axios";
import { Config } from "../config/config";

// import { API_URL } from '@/config';

function authRequestInterceptor(config: AxiosRequestConfig) {
  if (!config.headers) config.headers = {};
  config.headers.Accept = "application/json";
  return config;
}

export const accessibilityAxios = Axios.create({
  baseURL: Config.ACCESSIBILITY_URL,
});

export const carDetailsAxios = Axios.create({
  baseURL: Config.CAR_DETAILS_URL,
});

// accessibilityAxios.interceptors.request.use(authRequestInterceptor);
accessibilityAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// carDetailsAxios.interceptors.request.use(authRequestInterceptor);
carDetailsAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);
