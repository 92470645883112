import React from "react";
import { View, ActivityIndicator, StyleSheet } from "react-native";
import { Colors } from "../static";

export const Loading = () => {
  return (
    <View style={styles.loading}>
      <ActivityIndicator size="large" color={Colors.LightBlue} />
    </View>
  );
};

const styles = StyleSheet.create({
  loading: { marginTop: 100 },
});
